import React from 'react';
import { graphql, Link } from 'gatsby';
import { ArticleCard, SiteMetadata, TypeButtons } from '../components';
import { Layout } from '../layouts/Layout';

export default ({ data, pageContext: { previousPagePath, nextPagePath }, ...props }) => {
  return (
    <Layout>
      <SiteMetadata
        title="The Shows Must Go Online's Blog"
        description="Latest posts from The Shows Must Go Online"
        path={props.location.pathname}
      />
      <div className="mt-8 mx-4 text-indigo-800 text-base">
        <h2 className="text-3xl font-bold text-secondary mb-1 text-indigo-800">
          The Shows Must Go Online's Blog
        </h2>
      </div>
      <div>
        {data.blogPosts.nodes.map(node => <ArticleCard node={node} />)}
        <div className="container pt-4 pb-12 overflow-hidden justify-center align-center flex leading-tight text-white">
          {previousPagePath && <Link to={previousPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
            « Previous
        </Link>}
          {nextPagePath && <Link to={nextPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
            Next »
        </Link>}
        </div>
      </div>
      <TypeButtons title="You might also be interested in..."></TypeButtons>
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    blogPosts: allDatoCmsBlogpost(
      skip: $skip
      limit: $limit
      sort: {fields: meta___firstPublishedAt, order: DESC}
    ){
      nodes {
        title
        slug
        featureImage {
          url
          fluid(maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
        contentNode {
          childMarkdownRemark {
            excerpt(pruneLength: 250)
          }
        }
      }
    }
  }
`;
